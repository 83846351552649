// extracted by mini-css-extract-plugin
export var caption = "DatoImageGallery-module--caption--f7b4c";
export var controlButton = "DatoImageGallery-module--controlButton--69d48";
export var controls = "DatoImageGallery-module--controls--0af95";
export var fullImage = "DatoImageGallery-module--fullImage--976ff";
export var fullImageCont = "DatoImageGallery-module--fullImageCont--55e0f";
export var imageWithCaption = "DatoImageGallery-module--imageWithCaption--97c1d";
export var link = "DatoImageGallery-module--link--b3f91";
export var links = "DatoImageGallery-module--links--5a783";
export var pinIcon = "DatoImageGallery-module--pinIcon--04090";
export var thumb = "DatoImageGallery-module--thumb--23f8f";
export var thumbCont = "DatoImageGallery-module--thumbCont--19315";
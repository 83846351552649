import { graphql } from "gatsby"
import React from "react"
import SubHeading from "../../atoms/SubHeading"
import ArticleTileLayout from "../../layouts/ArticleTileLayout"
import ImageGalleryModal from "../../molecules/ImageGalleryModal"
import ImageWithCaption from "../../molecules/ImageWithCaption"
import DatoImage, { DatoImageProps } from "../DatoImage"
import * as styles from "./DatoImageGallery.module.scss"

export type DatoImageGalleryProps = {
  title?: string
  alt?: string
  images: Array<
    {
      title?: string
      url: string
    } & DatoImageProps
  >
}

const DatoImageGallery: React.FC<DatoImageGalleryProps> = props => {
  //console.log('Rendering DatoImageGallery', props)
  const photos = props.images

  const [open, setOpen] = React.useState(false)
  const [currentIndex, setCurrentIndex] = React.useState<number>(0)

  const next = () => {
    if (currentIndex !== null && currentIndex < photos.length - 1) setCurrentIndex(currentIndex + 1)
    else setCurrentIndex(0)
  }
  const prev = () => {
    if (currentIndex !== null && currentIndex > 0) setCurrentIndex(currentIndex - 1)
    else setCurrentIndex(photos.length - 1)
  }
  const openImg = (i: number) => {
    setCurrentIndex(i)
    setOpen(true)
  }

  return (
    <div>
      <SubHeading text={props.title} />
      <ArticleTileLayout>
        {props.images.map((img, i) => {
          return (
            <button key={img.url} className={styles.thumbCont} onClick={() => openImg(i)}>
              <ImageWithCaption
                image={<DatoImage {...img} alt={img.alt || ""} className={styles.thumb} />}
                caption={img.title}
              />
            </button>
          )
        })}
      </ArticleTileLayout>

      <ImageGalleryModal
        currentImage={photos[currentIndex]}
        open={open}
        onClose={() => setOpen(false)}
        onNext={next}
        onPrev={prev}
      />
    </div>
  )
}

export default DatoImageGallery

export const query = graphql`
  fragment DatoImageGallery on DatoCmsImageGallery {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    images {
      ...DatoImageDefault
      title
      url
    }
  }
`
